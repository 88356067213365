import partial from 'lodash/partial';
import reduce from 'lodash/reduce';

/**
 * substitute route params (i.e. ':id') in string
 */
function substituteParams(
  /** Route string with params to fill in */
  template: string,
  params?: { [key: string]: string }
) {
  return reduce(params, (accumulator, value, param) => accumulator.replace(RegExp(`:${param}\\??`), value), template);
}

/**
 * generates a full URL from a route using window.origin
 * @param  {String} route     route string
 * @param  {Object} params    route params
 * @return {String}
 */
function toUrl(route: string, params: { [key: string]: string }): string {
  const path = substituteParams(route, params);
  return `${window.location.origin}${path}`;
}

/**
 * Make a path definition
 */
export function makePath(
  /** route with param syntax e.g. `/route/:param` */
  route: string,
  /** name of route (used for analytics) */
  name: string
) {
  return {
    route,
    name: partial(substituteParams, name),
    toPath: partial(substituteParams, route),
    toUrl: partial(toUrl, route),
  };
}
