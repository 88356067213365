export const LOCATIONS = {
  DEFAULT: 'DEFAULT',
  NYC: 'NYC',
  WDC: 'WDC',
  CHI: 'CHI',
  LA: 'LA',
  MIA: 'MIA',
  MSP: 'MSP',
  BOS: 'BOS',
  AUS: 'AUS',
  PHL: 'PHL',
  ATL: 'ATL',
  DEN: 'DEN',
  NAS: 'NAS',
  HOU: 'HOU',
  TAM: 'TAM',
};

/*
 * The maxmind data maps IP addresses to Designated Market Areas (DMA)
 * a list of DMAs can be found here
 * http://www.spstechnical.com/DMACodes.htm
 *
 */

export const MAP_DMA_METRO_CODE_TO_CAPSULE_LOCATION = {
  501: LOCATIONS.NYC,
  613: LOCATIONS.MSP,
};

export const MAP_CAPSULE_FACILITY_ID_TO_LOCATION = {
  1: LOCATIONS.NYC,
  2: LOCATIONS.WDC,
  11: LOCATIONS.WDC,
  6: LOCATIONS.CHI,
  10: LOCATIONS.MSP,
  5: LOCATIONS.BOS,
  9: LOCATIONS.AUS,
  24: LOCATIONS.LA,
  25: LOCATIONS.MIA,
};

export const LOCATION_DISPLAY_NAME = {
  DEFAULT: 'Locations',
  NYC: 'New York City',
  MSP: 'Twin Cities',
  CHI: 'Chicago',
  BOS: 'Boston',
  WDC: 'Washington DC',
  AUS: 'Austin',
  LA: 'Los Angeles',
  MIA: 'Miami',
  PHL: 'Philadelphia',
  ATL: 'Atlanta',
  DEN: 'Denver',
  NAS: 'Nashville',
  HOU: 'Houston',
  TAM: 'Tampa',
};

export const GENERAL_CONTACT_INFO = {
  CAPSULE_TEXT_NUMBER: '+18889101808',
  CAPSULE_TEXT_NUMBER_FORMATTED: '(888) 910-1808',
  CAPSULE_CALL_NUMBER: '+18889101808',
  CAPSULE_CALL_NUMBER_FORMATTED: '(888) 910-1808',
};

export const LOCALIZED_CONTACT_INFO = {
  CAPSULE_TEXT_NUMBER: {
    DEFAULT: '+12126753900',
    NYC: '+12126753900',
    WDC: '+12028040399',
    CHI: '+13125897620',
    MSP: '+16125041000',
    BOS: '+16175442898',
    AUS: '+15128935000',
    LA: '+13237921444',
    MIA: '+13055159000',
    PHL: '+12158678777',
    ATL: '+14048000200',
    DEN: '+13032232502',
    NAS: '+16154884220',
    HOU: '+18326626000',
    TAM: '+18134990283',
  },
  CAPSULE_TEXT_NUMBER_FORMATTED: {
    DEFAULT: '(212) 675-3900',
    NYC: '(212) 675-3900',
    WDC: '(202) 804-0399',
    CHI: '(312) 589-7620',
    MSP: '(612) 504-1000',
    BOS: '(617) 544-2898',
    AUS: '(512) 893-5000',
    LA: '(323) 792-1444',
    MIA: '(305) 515-9000',
    PHL: '(215) 867-8777',
    ATL: '(404) 800-0200',
    DEN: '(303) 223-2502',
    NAS: '(615) 488-4220',
    HOU: '(832) 662-6000',
    TAM: '(813) 499-0283',
  },
  CAPSULE_CALL_NUMBER: {
    DEFAULT: '+12126753900',
    NYC: '+12126753900',
    WDC: '+12028040399',
    CHI: '+13125897620',
    MSP: '+16125041000',
    BOS: '+16175442898',
    AUS: '+15128935000',
    LA: '13237921444',
    MIA: '+13055159000',
    PHL: '+12158678777',
    ATL: '+14048000200',
    DEN: '+13032232502',
    NAS: '+16154884220',
    HOU: '+18326626000',
    TAM: '+18134990283',
  },
  CAPSULE_CALL_NUMBER_FORMATTED: {
    DEFAULT: '(212) 675-3900',
    NYC: '(212) 675-3900',
    WDC: '(202) 804-0399',
    CHI: '(312) 589-7620',
    MSP: '(612) 504-1000',
    BOS: '(617) 544-2898',
    AUS: '(512) 893-5000',
    LA: '(323) 792-1444',
    MIA: '(305) 515-9000',
    PHL: '(215) 867-8777',
    ATL: '(404) 800-0200',
    DEN: '(303) 223-2502',
    NAS: '(615) 488-4220',
    HOU: '(832) 662-6000',
    TAM: '(813) 499-0283',
  },
  CAPSULE_FAX_NUMBER: {
    DEFAULT: '+18449659882',
    NYC: '+18449659882',
    WDC: '+18448908153',
    CHI: '+13125897621',
    MSP: '+16125004742',
    BOS: '+16174851829',
    AUS: '+15122290795',
    PHL: '+12153304933',
    ATL: '+14043287951',
    DEN: '+13035295365',
    NAS: '+16152351299',
    HOU: '+18323073334',
    TAM: '+18134990283',
    MIA: '+13055159000',
  },
  CAPSULE_FAX_NUMBER_FORMATTED: {
    DEFAULT: '(844) 965-9882',
    NYC: '(844) 965-9882',
    WDC: '(844) 890-8153',
    CHI: '(312) 589-7621',
    MSP: '(612) 500-4742',
    BOS: '(617) 485-1829',
    AUS: '(512) 229-0795',
    PHL: '(215) 330-4933',
    ATL: '(404) 328-7951',
    DEN: '(303) 529-5365',
    NAS: '(615) 235-1299',
    HOU: '(832) 307-3334',
    TAM: '(813) 499-0283',
    MIA: '(305) 515-9000',
  },
  CAPSULE_ADDRESS_LINE_ONE: {
    DEFAULT: 'Capsule',
    NYC: 'Capsule',
    WDC: 'Capsule -- Washington DC',
    CHI: 'Capsule -- Chicago',
    MSP: 'Capsule -- Minneapolis',
    BOS: 'Capsule -- Boston',
    AUS: 'Capsule -- Austin',
    LA: 'Capsule -- Los Angeles',
    MIA: 'Capsule -- Miami',
    PHL: 'Capsule -- Philadelphia',
    ATL: 'Capsule -- Atlanta',
    DEN: 'Capsule -- Denver',
    NAS: 'Capsule -- Nashville',
    HOU: 'Capsule -- Houston',
    TAM: 'Capsule -- Tampa',
  },
  CAPSULE_ADDRESS_LINE_TWO: {
    DEFAULT: '122 West 146th St',
    NYC: '122 West 146th St',
    WDC: '1705 DeSales St NW',
    CHI: '661 N Wells St',
    MSP: '117 Washington Ave N.',
    BOS: '414 Boylston St',
    AUS: '210 W. 7th Street, Suite B',
    LA: '8065 W 3rd St',
    MIA: '168 NW 26th St',
    PHL: '117 South 20th St',
    ATL: '867 Peachtree St NE, Suite 102',
    DEN: '1441 Wazee Street, Ste. 103',
    NAS: '1207 Villa Place, Suite 101',
    HOU: '243 Westheimer Road, Suite 110',
    TAM: '1524 W Swann Avenue',
  },
  CAPSULE_ADDRESS_LINE_THREE: {
    DEFAULT: 'New York, NY 10039',
    NYC: 'New York, NY 10039',
    WDC: 'Washington, DC 20036',
    CHI: 'Chicago, IL 60654',
    MSP: 'Minneapolis, MN 55401',
    BOS: 'Boston, MA 02116',
    AUS: 'Austin, TX 78701',
    LA: 'Los Angeles, CA 90048',
    MIA: 'Miami, FL 33127',
    PHL: 'Philadelphia, PA 19103',
    ATL: 'Atlanta, GA 30308',
    DEN: 'Denver, CO 80202',
    NAS: 'Nashville, TN 37212',
    HOU: 'Houston, TX 77006',
    TAM: 'Tampa, FL 33606',
  },
  CAPSULE_HOURS_LINE_ONE: {
    DEFAULT: 'Mon-Fri: 8am-10pm',
    NYC: 'Mon-Fri: 8am-10pm',
    WDC: 'Mon-Fri: 9am-5pm',
    CHI: 'Mon-Fri: 9:30am-6:30pm',
    MSP: 'Mon-Fri: 9am-5pm',
    BOS: 'Mon-Fri: 9am-5pm',
  },
  CAPSULE_HOURS_LINE_TWO: {
    DEFAULT: 'Sat-Sun: 10am-6pm',
    NYC: 'Sat-Sun: 10am-6pm',
    WDC: null,
    CHI: 'Sat: 9am-1pm',
    MSP: 'Sat-Sun: 11am-4pm',
    BOS: null,
  },
  CAPSULE_HOURS_LINE_THREE: {
    DEFAULT: null,
    NYC: null,
    WDC: null,
    CHI: 'Sun: Closed',
    MSP: null,
    BOS: null,
    AUS: null,
  },
  CAPSULE_HOURS_LINE_ONE_DAYONLY: {
    DEFAULT: 'Mon-Fri:',
    NYC: 'Mon-Fri:',
    WDC: 'Mon-Fri:',
    CHI: 'Mon-Fri:',
    MSP: 'Mon-Fri:',
    BOS: 'Mon-Fri:',
    AUS: 'Mon-Fri:',
  },
  CAPSULE_HOURS_LINE_ONE_TIMEONLY: {
    DEFAULT: '8am-10pm',
    NYC: '8am-10pm',
    WDC: '9am-6pm',
    CHI: '8am-8pm',
    MSP: '8am-8pm',
    BOS: '9am-5pm',
    AUS: '9am-7pm',
  },
  CAPSULE_HOURS_LINE_TWO_DAYONLY: {
    DEFAULT: 'Sat-Sun:',
    NYC: 'Sat-Sun:',
    WDC: 'Sat-Sun:',
    CHI: 'Sat:',
    MSP: 'Sat-Sun:',
    BOS: null,
    AUS: 'Sat-Sun:',
  },
  CAPSULE_HOURS_LINE_TWO_TIMEONLY: {
    DEFAULT: '10am-6pm',
    NYC: '10am-6pm',
    WDC: '11am-4pm',
    CHI: '9am-4pm',
    MSP: '10am-6pm',
    BOS: null,
    AUS: '9am-4pm',
  },
  CAPSULE_HOURS_LINE_THREE_DAYONLY: {
    DEFAULT: null,
    NYC: null,
    WDC: null,
    CHI: 'Sun:',
    MSP: null,
    BOS: null,
    AUS: null,
  },
  CAPSULE_HOURS_LINE_THREE_TIMEONLY: {
    DEFAULT: null,
    NYC: null,
    WDC: null,
    CHI: 'Closed',
    MSP: null,
    BOS: null,
    AUS: null,
  },
  CAPSULE_DELIVERY_HOURS_LINE_ONE_DAYONLY: {
    DEFAULT: 'Monday-Friday',
    NYC: 'Monday-Friday',
    WDC: 'Monday-Friday',
    CHI: 'Monday-Friday',
    MSP: 'Monday-Friday',
    BOS: 'Monday-Friday',
  },
  CAPSULE_DELIVERY_HOURS_LINE_ONE_TIMEONLY: {
    DEFAULT: '8AM-10PM',
    NYC: '8AM-10PM',
    WDC: '10AM-6PM',
    CHI: '10AM-6PM',
    MSP: '10AM-6PM',
    BOS: '9am-5pm',
  },
  CAPSULE_DELIVERY_HOURS_LINE_TWO_DAYONLY: {
    DEFAULT: 'Saturday-Sunday',
    NYC: 'Saturday-Sunday',
    WDC: null,
    CHI: null,
    MSP: null,
    BOS: null,
  },
  CAPSULE_DELIVERY_HOURS_LINE_TWO_TIMEONLY: {
    DEFAULT: '10AM-6PM',
    NYC: '10AM-6PM',
    WDC: null,
    CHI: null,
    MSP: null,
    BOS: null,
  },
};

export const LOCALIZED_CITY_MONIKER = {
  DEFAULT: 'New York City',
  NYC: 'New York City',
  WDC: 'the Beltway',
  CHI: 'Chicagoland',
  MSP: 'the Twin Cities',
  BOS: 'and around Boston',
  AUS: 'Austin',
  LA: 'Los Angeles',
  MIA: 'Miami',
  PHL: 'Philadelphia',
  ATL: 'Atlanta',
  DEN: 'Denver',
  NAS: 'Nashville',
  HOU: 'Houston',
  TAM: 'Tampa',
};

export const WEEK = {
  DAYS: 'Mon-Fri',
  ENDS: 'Sat-Sun',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun',
};

/**
 * Google Maps id for Capsule address
 * https://developers.google.com/places/web-service/place-id
 */
export const GOOGLE_MAPS_ID_FOR_CAPSULE = {
  DEFAULT: 'ChIJO1gWBaVZwokRdOecH5pGnaA',
  NYC: 'ChIJO1gWBaVZwokRdOecH5pGnaA',
  CHI: 'ChIJkVO4YoQsDogRx0mZW8-5cqA',
  MSP: 'ChIJd0xE9Zwzs1IRtUES9znipTM',
  BOS: 'ChIJFb4Ae7N744kRU0a-wPQ0Pr8',
  WDC: 'ChIJ04wjU_G3t4kRk5oStdWP43k',
  AUS: 'ChIJvyRJhGS1RIYRcOeGycR8Keg',
  PHL: 'ChIJu12sQTfGxokR3WvX4NfH63g',
  ATL: 'ChIJwU4OW2gE9YgRBeZDWdrr1rw',
  DEN: 'ChIJSxBBFcR4bIcRus4FKH-qOuE',
  NAS: 'ChIJd0YVyZpmZIgRDbNcFoYTdZ0',
  HOU: 'ChIJQb9wE2a_QIYRJ9MmikRj2fU',
  TAM: 'ChIJW7-JCmLDwogRHM9QfXljbUo',
  MIA: 'ChIJef7Lga222YgRoaCdYX2mBD8',
};

// In the event facility isn't loaded, this is the NY facility as a fallback.
export const FACILITY_FALLBACK = {
  isFetching: false,
  id: '1',
  code: 'NYC-MN',
  address: {
    id: '3',
    name: 'Capsule - New York',
    address: '122 West 146th St',
    city: 'New York',
    state: 'NY',
    zipCode: '10039',
    latitude: 40.8215207,
    longitude: -73.9372221,
  },
  phone: '(212) 675-3900',
  fax: '(844) 965-9882',
  isCurrentlyOpen: false,
  nextOpenDay: 'Friday',
  hours: [
    {
      dayName: 'Monday',
      formattedHours: '9 AM – 5 PM',
      openingTime: '9 AM',
      closingTime: '5 PM',
    },
    {
      dayName: 'Tuesday',
      formattedHours: 'Closed',
      openingTime: null,
      closingTime: null,
    },
    {
      dayName: 'Wednesday',
      formattedHours: '9 AM – 5 PM',
      openingTime: '9 AM',
      closingTime: '5 PM',
    },
    {
      dayName: 'Thursday',
      formattedHours: '9 AM – 5 PM',
      openingTime: '9 AM',
      closingTime: '5 PM',
    },
    {
      dayName: 'Friday',
      formattedHours: '9 AM – 5 PM',
      openingTime: '9 AM',
      closingTime: '5 PM',
    },
    {
      dayName: 'Saturday',
      formattedHours: '9 AM – 5 PM',
      openingTime: '9 AM',
      closingTime: '5 PM',
    },
    {
      dayName: 'Sunday',
      formattedHours: '9 AM – 3 PM',
      openingTime: '9 AM',
      closingTime: '3 PM',
    },
  ],
};
