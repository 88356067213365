import type { ThemeColorType } from '@capsule/consumer-design-system';

import styled from 'styled-components';

export const StyledLink = styled.a<{ color?: ThemeColorType }>`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.capsuleGreen60)};
  cursor: 'pointer';
  text-decoration: none;
`;

export const StyledNavLink = styled.a<{ color?: ThemeColorType }>`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.capsuleBlue50)};
  cursor: 'pointer';
  text-decoration: none;
  font-size: ${props => props.theme.fontSizes.body};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  line-height: ${props => props.theme.lineHeights.headerOne};
`;

export const StyledSimpleLink = styled.a<{ color?: ThemeColorType; small?: boolean }>`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.capsuleGreen80)};
  cursor: pointer;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => (props.small ? props.theme.fontSizes.small : props.theme.fontSizes.body)};
  font-weight: ${props => (props.small ? props.theme.fontWeights.semiBold : props.theme.fontWeights.book)};
  line-height: ${props => (props.small ? props.theme.lineHeights.small : props.theme.lineHeights.body)};
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledMediumLink = styled.a`
  display: inline-block;
  text-transform: uppercase !important;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.mercury} !important;
  font-size: ${props => props.theme.fontSizes.body};
  font-weight: ${props => props.theme.fontWeights.book};
  line-height: ${props => props.theme.lineHeights.body};
  border-bottom: 1px solid ${props => props.theme.colors.capsuleGreen60};
  color: ${props => props.theme.colors.capsuleGreen80};
  letter-spacing: ${props => props.theme.letterSpacings.one};

  &:hover {
    border-bottom-color: transparent;
    color: ${props => props.theme.colors.capsuleGreen80};
  }

  &:focus {
    border-bottom: 2px solid ${props => props.theme.colors.capsuleGreen80};
    color: ${props => props.theme.colors.capsuleGreen80};
  }
`;
