// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
// Check if user-agent is on desktop or mobile
const mobileBrowser =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;

export const isMobileBrowser = (agents: string) => {
  const isMobileBrowser = mobileBrowser.test(agents);
  return isMobileBrowser;
};

export const isMobileSafari = () => {
  const ua = typeof window !== 'undefined' ? window.navigator.userAgent : '';
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  return iOSSafari;
};

export const getOS = (agent: string | null = null) => {
  const userAgent = agent || window.navigator.userAgent || '';
  if (/Macintosh/.test(userAgent)) return 'Mac OS';
  if (/Windows/.test(userAgent)) return 'Windows';
  if (/iPhone|iPad/.test(userAgent)) return 'iOS';
  if (/Android/.test(userAgent)) return 'Android';
  if (/Linux/.test(userAgent)) return 'Linux';
  return null;
};
