export const ALERT_BANNER_DISMISS_BUTTON = 'ALERT_BANNER_DISMISS_BUTTON';
export const CAPSULE_ICON = 'CAPSULE_ICON';
export const CARD_MENU = 'CARD_MENU';
export const CARD_MENU_BUTTON = `${CARD_MENU}_BUTTON`;
export const CARD_MENU_POPUP_CONTAINER = `${CARD_MENU}_POPUP_CONTAINER`;
export const CHAT_FILE_INPUT = 'selectImageToUpload';
export const CHAT_IMAGE_PREVIEW = 'CHAT_IMAGE_PREVIEW';
export const CHAT_MESSAGES_EMPTY = 'CHAT_MESSAGES_EMPTY';
export const CHAT_MESSAGES_LOADING = 'CHAT_MESSAGES_LOADING;';
export const CHAT_SUBMIT_BUTTON = 'sendMessageButton';
export const CHAT_TEXT_INPUT = 'typeMessageInput';
export const CLOSE_MODAL_BUTTON = 'CLOSE_MODAL_BUTTON';
export const DELETE_INSURANCE_BUTTON = 'DELETE_INSURANCE_BUTTON';
export const FORGOT_PASSWORD_FORM_SUBMIT_BUTTON = 'FORGOT_PASSWORD_FORM_SUBMIT_BUTTON';
export const LANDING_PAGE_CAROUSEL = 'LANDING_PAGE_CAROUSEL';
export const LEGACY_SKELETON_LOADER = 'LEGACY_SKELETON_LOADER';
export const MEDICATION_CARD = 'MEDICATION_CARD';
export const NAV_HEADER = 'navHeader';
export const PATIENT_ACCORDION_BUTTON = 'PATIENT_ACCORDION_BUTTON';
export const PATIENT_ACCORDION_CONTENT = 'PATIENT_ACCORDION_CONTENT';
export const SAVE_FAMILY_MEMBER_BUTTON = 'SAVE_FAMILY_MEMBER_BUTTON';
export const TEXT_INPUT_ERROR_TEXT = 'errorHelperText';
export const TEXT_INPUT_HELPER_TEXT = 'helperText';
export const CHECKOUT_ADD_PHOTO_ID_BUTTON = 'CHECKOUT_ADD_PHOTO_ID_BUTTON';
export const LOGIN_PAGE_HEADER_ELEMENT = 'LOGIN_PAGE_HEADER_ELEMENT';
export const LOGIN_PAGE_EMAIL_INPUT_ELEMENT = 'LOGIN_PAGE_EMAIL_INPUT_ELEMENT';
export const LOGIN_PAGE_PASSWORD_INPUT_ELEMENT = 'LOGIN_PAGE_PASSWORD_INPUT_ELEMENT';
export const ORDER_BANNER_CTA = 'ORDER_BANNER_CTA';
export const ORDER_BANNER_CHAT_CTA = 'ORDER_BANNER_CHAT_CTA';
export const REFIGERATION_BANNER = 'REFIGERATION_BANNER';
export const FEATURE_LAYOUT_TITLE = 'FEATURE_LAYOUT_TITLE';
